import React, { useState, useEffect } from "react";
import ProgressBar from "./ProgressBar.jsx";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  submitWord,
  setTimeTook,
  setWordChain,
  incrementGamesPlayedAsync,
} from "./gameSlice";
import "../styles/inputfield.css";
import "../styles/playpage.css";

import Button1 from "./button1";
import InputField from "./InputField";

function PlayPage() {
  const { incoming, wordChain, timeLimit } = useSelector((state) => state.game);

  const totalTime = timeLimit || 20; // total time in seconds
  const [timeLeft, setTimeLeft] = useState(totalTime);
  const [word, setWord] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const timeTaken = totalTime - timeLeft;

  const [dictionary, setDictionary] = useState([]);
  const [validEndings, setValidEndings] = useState(new Set());
  const [errorMessage, setErrorMessage] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);
  const [key, setKey] = useState(false);

  let incomingWordChain = wordChain;

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        const updatedTime = prevTime - 1;
        if (updatedTime <= 0) {
          clearInterval(timer);
          dispatch(setTimeTook(totalTime - updatedTime));
          navigate("/result");
        }
        return updatedTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [dispatch, navigate, totalTime]);

  useEffect(() => {
    fetch("/startsWith.csv")
      .then((response) => response.text())
      .then((text) => {
        const endings = text
          .split(/\r?\n/)
          .map((line) => line.trim().toLowerCase());
        // console.log(endings);
        setValidEndings(new Set(endings));
      });
  }, []);

  useEffect(() => {
    fetch("/dictionaryWordlist.txt")
      .then((response) => response.text())
      .then((text) => {
        const words = text.split(/\r?\n/).map((word) => word.toLowerCase());
        setDictionary(words);
      })
      .catch((error) => {
        console.error("Failed to load dictionary:", error);
      });
  }, []);

  // const handleInputChange = (event) => {
  //   setWord(event.target.value);
  // };

  const incomingWordEnd = incoming.slice(-2).toLowerCase();

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    const trimmedWord = word.trim().toLowerCase();
    const wordEnd = trimmedWord.slice(-2);

    if (
      trimmedWord.startsWith(incomingWordEnd) &&
      dictionary.includes(trimmedWord) &&
      validEndings.has(wordEnd)
    ) {
      incomingWordChain++;
      // console.log("Submitted Word:", trimmedWord);
      dispatch(submitWord(trimmedWord));
      dispatch(setTimeTook(timeTaken));
      dispatch(setWordChain(incomingWordChain));
      setWord("");
      dispatch(incrementGamesPlayedAsync());
      navigate("/result");
    } else {
      setIsInvalid(true);
      setTimeout(() => {
        setIsInvalid(false);
        setWord("");
        setKey(!key);
      }, 500); // Reset after animation duration
      setErrorMessage(
        "Word is not valid, or does not end with allowed ending letters."
      );
      setTimeout(() => setErrorMessage(""), 3000);
    }
  };

  return (
    <div className="playcontainer">
      <ProgressBar currentTime={timeLeft} totalTime={totalTime} />
      <div style={{ justifyContent: "center", margin: "20%" }}>
        <h4>
          The word is: <h3>{incoming}</h3>
        </h4>
      </div>
      <InputField
        key={key}
        initialChars={incomingWordEnd}
        onChange={setWord}
        isInvalid={isInvalid}
        onEnterPress={handleSubmit}
      />
      <div className="button">
        <Button1 onClick={handleSubmit}>Submit</Button1>
      </div>{" "}
      {errorMessage && <p style={{ color: "#f6d252" }}>{errorMessage}</p>}
    </div>
  );
}

export default PlayPage;
