// import React from "react";

// export default function ProgressBar({ currentTime, totalTime }) {
//   const percentage = (currentTime / totalTime) * 100;
//   let color;

//   if (currentTime > 10) {
//     color = "#5e49ec";
//   } else if (currentTime > 5) {
//     color = "#5e49ec";
//   } else {
//     color = "#f6d252";
//   }

//   return (
//     <div
//       style={{
//         width: "100%",
//         backgroundColor: "#1a1a2e",
//         height: "20px",
//         borderRadius: "5px",
//         flexDirection: "row",
//         flex: "auto",
//         justifyContent: "center",
//       }}
//     >
//       <div
//         style={{
//           height: "100%",
//           width: `${percentage}%`,
//           backgroundColor: color,
//           borderRadius: "5px",
//           transition: "width 1s linear, background-color 1s linear",
//         }}
//       ></div>
//       <div style={{}}>
//         <p>00:{currentTime}</p>
//       </div>
//     </div>
//   );
// }
import React from "react";
import "../styles/progressbar.css";

export default function ProgressBar({ currentTime, totalTime }) {
  const percentage = (currentTime / totalTime) * 100;
  let color;

  if (currentTime > 10) {
    color = "#5e49ec";
  } else if (currentTime > 5) {
    color = "#5e49ec";
  } else {
    color = "#f6d252";
  }

  return (
    <div style={{ justifyContent: "center", marginLeft: 20 }}>
      <div
        style={{
          width: "100%",
          backgroundColor: "#1a1a2e",
          height: "15px",
          borderRadius: "5px",
          position: "relative",
        }}
      >
        <div
          style={{
            height: "100%",
            width: `${percentage}%`,
            backgroundColor: color,
            borderRadius: "5px",
            transition: "width 1s linear",
            position: "relative",
          }}
        >
          {/* Moving Image */}
          <img
            src="/asteriod.png"
            alt="Moving Icon"
            style={{
              position: "absolute",
              right: -20,
              top: "-100%",
              transform: "translateY(-50%)",
              width: "50px",
              height: "50px",
              animation: "wiggle 1s infinite",
            }}
          />
        </div>

        <img
          src="/Earth.png"
          alt="Static Icon"
          style={{
            position: "absolute",
            left: -60,
            top: "50%",
            transform: "translateY(-50%)",
            width: "75px",
            height: "75px",
          }}
        />
      </div>
    </div>
  );
}
