import React, { useState } from "react";
import "../styles/button1.css"; // Import the custom CSS

const Button1 = ({ onClick, children, className }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = (event) => {
    if (isPressed) {
      setIsPressed(false);
      onClick && onClick(event);
    }
  };

  return (
    <button
      className={`pill-button ${isPressed ? "pressed" : ""} ${className}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {children}
    </button>
  );
};

export default Button1;
