import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import ReadyPage from "./components/ReadyPage.jsx";
import PlayPage from "./components/PlayPage.jsx";
import ResultPage from "./components/ResultPage.jsx";
import store from "./components/store";
import "./App.css"; // Ensure this import is correct

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <div id="modal-root"></div>

          {/* <div className=".navBar">Nav Bar</div> */}
          <Routes>
            <Route path="/ready" element={<ReadyPage />} />
            <Route path="/" element={<ReadyPage />} />

            {/* <Route path="/ready/:word?/:number?" element={<ReadyPage />} /> */}

            <Route path="/play" element={<PlayPage />} />
            <Route path="/result" element={<ResultPage />} />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
