import React, { useState } from "react";
import Button2 from "./button2.jsx";
import Button1 from "./button1.jsx";
import { useDispatch } from "react-redux";
import { loadTotalCharacters, saveTotalCharacters } from "./gameSlice.jsx";

function SpendModal({
  isOpen,
  onClose,
  children,
  setTime,
  selectedTime,
  totalAlltimeCharacters,
}) {
  const dispatch = useDispatch();
  const [tempTime, setTempTime] = useState(selectedTime);

  if (!isOpen) return null;

  // Function to handle the spending of characters
  const handleSpend = () => {
    const timeDifference = 20 - tempTime;
    const characterCost = timeDifference * 10;
    if (totalAlltimeCharacters >= characterCost) {
      saveTotalCharacters(totalAlltimeCharacters - characterCost);
      setTime(tempTime);
      dispatch(
        saveTotalCharacters(totalAlltimeCharacters - characterCost)
      ).then(() => dispatch(loadTotalCharacters()));
    } else {
      alert("Not enough characters!");
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          padding: 20,
          background: "#484858",
          borderRadius: "15px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          maxWidth: "80%",
          maxHeight: "90%",
          overflowY: "auto",
        }}
      >
        {children}
        <div>
          <h3>Characters: {totalAlltimeCharacters}</h3>
          <p>
            Spend your characters to reduce the time of the next person: Each
            second under 20 costs 10 Characters.
          </p>
          <label>Select time limit reduction (seconds): {tempTime}</label>
          <div style={{ marginTop: "20px" }}>
            <input
              type="range"
              min="5"
              max="20"
              value={tempTime}
              onChange={(e) => setTempTime(parseInt(e.target.value, 10))}
              style={{
                width: "100%",
                margin: "10px 0",
                backgroundColor: "#f6d252",
              }}
            />
          </div>
          <div
            style={{
              flex: "auto",
              marginTop: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <div style={{ marginRight: "5px" }}>
              <Button1 onClick={handleSpend}>Spend</Button1>
            </div>
            <div style={{ marginLeft: "5px" }}>
              <Button2
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SpendModal;
