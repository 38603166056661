import React, { useState } from "react";
import ReactDOM from "react-dom";
import "../styles/foldermodal.css";
import watoIcon from "../assets/wato-icon.png";
import percentlIcon from "../assets/percentl-icon.png";

function FolderModal() {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      setIsOpen(false);
    }
  };

  const tilesData = [
    {
      imageUrl: percentlIcon,
      linkUrl: "https://apps.apple.com/us/app/percentl/id6476887515",
      description: "Percentl",
      formatImg: "apple-logo.png",
    },
    {
      imageUrl: watoIcon,
      linkUrl:
        "https://apps.apple.com/us/app/wato-what-are-the-odds/id6470747743",
      description: "Wato",
      formatImg: "apple-logo.png",
    },
    {
      imageUrl: watoIcon,
      linkUrl:
        "https://play.google.com/store/apps/details?id=com.starantini.wato",
      description: "Wato",
      formatImg: "android-logo.png",
    },
  ];

  const modalContent = isOpen ? (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          {tilesData.map((tile, index) => (
            <div
              key={index}
              className="tile"
              onClick={() => (window.location.href = tile.linkUrl)}
            >
              <div
                style={{
                  width: "35%",
                  height: "auto",
                  backgroundColor: "white",
                  padding: "6px",
                  position: "relative",
                  top: 30,
                  left: 70,
                  borderRadius: "30px",
                }}
              >
                <img
                  src={tile.formatImg}
                  alt={`Link ${index + 1}`}
                  style={{
                    width:
                      tile.formatImg === "android-logo.png" ? "80%" : "100%",
                    height: "auto",
                  }}
                />
              </div>
              <img
                src={tile.imageUrl}
                alt={`Link ${index + 1}`}
                style={{ width: "100%", height: "auto", borderRadius: "10px" }}
              />
              <div>{tile.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;

  const imageUrls = [percentlIcon, watoIcon];

  const gridTiles = [];
  for (let i = 0; i < 2; i++) {
    gridTiles.push(
      <div key={i} className="previewtile">
        <img
          src={imageUrls[i % imageUrls.length]}
          alt={`Tile ${i + 1}`}
          style={{ width: "100%", height: "100%", borderRadius: "5px" }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="foldercontainer" onClick={() => setIsOpen(true)}>
        {gridTiles}
      </div>
      {modalContent &&
        ReactDOM.createPortal(
          modalContent,
          document.getElementById("modal-root")
        )}
    </div>
  );
}

export default FolderModal;
