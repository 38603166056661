import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import css file
import "../styles/carousel.css";
import FolderModal from "./FolderModal";
import Button1 from "./button1";

function WebCarousel() {
  const navigateToReddit = () => {
    window.location.href = "https://www.reddit.com/r/endoftheword/";
  };

  const carouselItems = [
    {
      title: "More Games",
      text: null,
      new: true,
      beta: true,
      component: <FolderModal />,
    },
    {
      title: "Tell us What you think",
      text: "Fill out the short feedback form below, we want to keep improving the game for you.",
      new: false,
      beta: false,
      component: (
        <div
          onClick={() =>
            (window.location.href =
              "https://docs.google.com/forms/d/e/1FAIpQLSf6z2Q7Lc5TdhfXD7KusncK608HPARkp3DPxjpiIHFviGF66w/viewform?usp=sf_link")
          }
        >
          <div
            style={{
              width: "60px",
              position: "relative",
              top: -10,
              left: 45,
            }}
          >
            <Button1>Feedback</Button1>
          </div>
        </div>
      ),
    },
    {
      title: "Join the Community",
      text: "r/endoftheword is the place to discuss the End of the Words",
      new: false,
      beta: false,
      component: (
        <div onClick={navigateToReddit}>
          <img
            style={{ width: "60px", position: "relative", top: -10 }}
            src="/reddit-icon.png"
          ></img>
        </div>
      ),
    },
    {
      title: "From the Team",
      text: "Thank you for playing, and sharing EOTW. It means the word errrm...world to us.",
      new: false,
      beta: false,
    },
  ];

  // Optional responsive adjustments or actions can be added here

  const renderItem = (item, index) => {
    return (
      <div key={index} className="carousel-item">
        {item.new && <span className="new-indicator">New!</span>}
        <h4>{item.title}</h4>
        <p>{item.text}</p>
        {item.component}
      </div>
    );
  };

  return (
    <div className="carouselcontainer">
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        // autoPlay={true}
        // interval={7000}
        centerMode={true}
        width={375}
        showIndicators={false}
      >
        {carouselItems.map((item, index) => renderItem(item, index))}
      </Carousel>
    </div>
  );
}

export default WebCarousel;
