import React, { useState } from "react";
import "../styles/button2.css"; // Import the custom CSS

const Button2 = ({ onClick, children }) => {
  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = (event) => {
    if (isPressed) {
      setIsPressed(false);
      onClick && onClick(event);
    }
  };
  // const handleMouseLeave = () => {
  //   console.log("mouse leave");

  //   setIsPressed(false); // Reset isPressed when mouse leaves the button
  // };

  return (
    <button
      className={`pill-button-two ${isPressed ? "pressed" : ""}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      // onMouseLeave={handleMouseUp} // Reset on mouse leave
    >
      {children}
    </button>
  );
};

export default Button2;
