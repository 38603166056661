import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import supabase from "../supabaseClient.js";

export const incrementGamesPlayedAsync = createAsyncThunk(
  "gamesPlayed/increment",
  async () => {
    const dateDevice = new Date();
    const year = dateDevice.getFullYear();
    const month = dateDevice.getMonth() + 1;
    const day = dateDevice.getDate();
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedDay = day.toString().padStart(2, "0");
    let clientDate = `${year}-${formattedMonth}-${formattedDay}`;

    console.log(clientDate);

    let { data, error } = await supabase
      .rpc("Increment_eotw_gamesplayed", { _target_date: clientDate })
      .then((response) => {
        if (response.error) {
          console.error("Error:", response.error);
        } else {
        }
      });
  }
);

export const incrementSharesAsync = createAsyncThunk(
  "saveShares/saveShares",
  async (date) => {
    const dateDevice = new Date();
    const year = dateDevice.getFullYear();
    const month = dateDevice.getMonth() + 1;
    const day = dateDevice.getDate();
    const formattedMonth = month.toString().padStart(2, "0");
    const formattedDay = day.toString().padStart(2, "0");
    let clientDate = `${year}-${formattedMonth}-${formattedDay}`;

    let { data, error } = await supabase
      .rpc("shared_eotw", { _target_date: clientDate })
      .then((response) => {
        if (response.error) {
          console.error("Error:", response.error);
        } else {
        }
      });
  }
);

export const loadTotalCharacters = createAsyncThunk(
  "game/loadTotalCharacters",
  async (_, { rejectWithValue }) => {
    try {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; totalAlltimeCharacters=`);
      if (parts.length === 2) {
        return parseInt(parts.pop().split(";").shift(), 10);
      }
      return 0;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const saveTotalCharacters = createAsyncThunk(
  "game/saveTotalCharacters",
  async (totalCharacters, { rejectWithValue }) => {
    try {
      const date = new Date();
      date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 10000); // Save for 1 year
      const expires = `expires=${date.toUTCString()}`;
      document.cookie = `totalAlltimeCharacters=${totalCharacters}; ${expires}; path=/`;
      return totalCharacters;
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const gameSlice = createSlice({
  name: "game",
  initialState: {
    incoming: "",
    outgoing: "",
    wordChain: 0,
    timeTook: 0,
    timeLimit: 20,
    totalAlltimeCharacters: 0,
  },
  reducers: {
    setWord: (state, action) => {
      state.incoming = action.payload;
    },
    submitWord: (state, action) => {
      state.outgoing = action.payload;
    },
    setWordChain: (state, action) => {
      state.wordChain = action.payload;
    },
    setTimeTook: (state, action) => {
      state.timeTook = action.payload;
    },
    setTimeLimit: (state, action) => {
      state.timeLimit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTotalCharacters.fulfilled, (state, action) => {
        state.totalAlltimeCharacters = action.payload;
      })
      .addCase(saveTotalCharacters.fulfilled, (state, action) => {
        state.totalAlltimeCharacters = action.payload;
      });
  },
});

export const { setWord, submitWord, setWordChain, setTimeTook, setTimeLimit } =
  gameSlice.actions;

export default gameSlice.reducer;
