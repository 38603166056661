import React, { useState, useEffect, useRef } from "react";
import "../styles/inputfield.css";

const InputField = ({ initialChars, onChange, isInvalid, onEnterPress }) => {
  const [userInput, setUserInput] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
    const length = userInput.length;
    inputRef.current.setSelectionRange(length, length);
  }, [userInput]);

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
    onChange(initialChars + event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onEnterPress();
    }
  };

  const focusInput = () => {
    inputRef.current.focus();
    const length = userInput.length;
    inputRef.current.setSelectionRange(length, length);
  };

  return (
    <div
      className={`custom-input-container ${isInvalid ? "shake" : ""}`}
      onClick={focusInput}
    >
      <span className="char">{initialChars[0]}</span>{" "}
      <span className="char">{initialChars[1]}</span>
      {userInput.split("").map((char, index) => (
        <span key={index} className="char">
          {char}
        </span>
      ))}
      <input
        ref={inputRef}
        type="text"
        value={userInput}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        onBlur={focusInput}
        className="hidden-input"
        autoFocus
      />
      <span className="cursor">&#8203;</span>
    </div>
  );
};

export default InputField;
