import React, { useState, useEffect, useRef } from "react";
import "../styles/resultpage.css";
import WebCarousel from "./Carousel.jsx";
import ShareBox from "./shareBox.jsx";
import Feedback from "./FeedBack.jsx";
import Button1 from "./button1.jsx";
import Button2 from "./button2.jsx";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  incrementSharesAsync,
  loadTotalCharacters,
  saveTotalCharacters,
} from "./gameSlice.jsx";
import SpendModal from "./SpendModal.jsx";

function ResultPage() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  // const [totalAlltimeCharacters, setTotalAlltimeCharacters] = useState(0);
  const hasCheckedCookie = useRef(false);
  const [modalOpen, setModalOpen] = useState(false);

  const { outgoing, wordChain, totalAlltimeCharacters, timeTook } = useSelector(
    (state) => state.game
  );
  const [selectedTime, setSelectedTime] = useState(20);

  const [copySuccess, setCopySuccess] = useState("");

  useEffect(() => {
    dispatch(loadTotalCharacters());
  }, [dispatch]);

  useEffect(() => {
    if (hasCheckedCookie.current) return;
    hasCheckedCookie.current = true;

    const savedTotal = parseInt(getCookie("totalOutgoingLength"), 10) || 0;

    if (outgoing.length > 2) {
      const newTotal =
        totalAlltimeCharacters +
        outgoing.length +
        (timeTook <= 5 ? 3 : timeTook <= 10 ? 2 : timeTook <= 15 ? 1 : 0);
      dispatch(saveTotalCharacters(newTotal));
      setCookie("totalOutgoingLength", newTotal, 365);
      deleteCookie("decodedWord");
    } else {
      dispatch(saveTotalCharacters(savedTotal));
    }
  }, [outgoing]);

  const [isWiggling, setIsWiggling] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsWiggling(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  function copyToClipboard(e) {
    navigator.clipboard.writeText(linkToShare).then(
      () => {
        setCopySuccess("copied!");
        dispatch(incrementSharesAsync());
        setTimeout(() => setCopySuccess(""), 2000);
      },
      (err) => {
        setCopySuccess("Failed to copy!"); // Handle failure case
      }
    );
  }

  function shareLink() {
    if (navigator.share) {
      navigator
        .share({
          title: "Challenge Your Friend",
          text: "You’ve been challenged...to stop the end of the word! Can you come up with a word in 20secs or less?",
          url: linkToShare,
        })
        .then(() => {
          dispatch(incrementSharesAsync());
          // console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      // Fallback for devices that do not support the Web Share API
      copyToClipboard();
    }
  }

  function getRandomLetter() {
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    return alphabet[Math.floor(Math.random() * alphabet.length)];
  }

  // Function to encode a word and pad to 28 characters
  function encodeWord(word) {
    let rail1 = "",
      rail2 = "";
    for (let i = 0; i < word.length; i++) {
      if (i % 2 === 0) {
        rail1 += word[i];
      } else {
        rail2 += word[i];
      }
    }
    let encodedWord = rail1 + rail2;
    let additionalCharacters = 0;

    // Pad the word to 28 characters with random letters
    while (encodedWord.length < 28) {
      encodedWord += getRandomLetter();
      additionalCharacters++;
    }

    // Convert the additional characters count to a reversed four-digit string
    const countStr = ("000" + additionalCharacters).slice(-4);
    const reversedCountStr = countStr.split("").reverse().join("");

    return `${encodedWord}${reversedCountStr}`;
  }

  function encodeNumber(number) {
    const base = number.toString().split("").reverse().join(""); // Reverse the digits of the number
    let encodedNumber = "";
    let shift = 3; // Shift each digit by 3, wrapping around at 10

    for (let i = 0; i < base.length; i++) {
      let digit = parseInt(base[i], 10) + shift;
      digit = digit % 10; // Wrap around if the digit + shift exceeds 9
      encodedNumber += digit.toString();
    }

    // Record the original length of the encoded number
    const originalLength = encodedNumber.length.toString().padStart(2, "0"); // Ensure it's always two characters long

    // Pad the encoded number to make it less obvious
    while (encodedNumber.length < 8) {
      encodedNumber += Math.floor(Math.random() * 10).toString();
    }

    return encodedNumber + originalLength; // Append the length at the end
  }

  function encodeTime(time) {
    const base = time.toString().split("").reverse().join(""); // Reverse the digits
    let encodedTime = "";
    let shift = 3; // Shift amount

    for (let i = 0; i < base.length; i++) {
      let digit = parseInt(base[i], 10) + shift;
      digit = digit % 10; // Wrap around if the digit + shift exceeds 9
      encodedTime += digit.toString();
    }

    // Record the original length of the encoded time
    const originalLength = encodedTime.length.toString().padStart(2, "0"); // Ensure it's always two characters long

    // Pad the encoded time to make it less obvious
    while (encodedTime.length < 8) {
      encodedTime += Math.floor(Math.random() * 10).toString();
    }

    return encodedTime + originalLength; // Append the length at the end
  }

  // Update the createLink function to use encodeNumber
  function createLink(word, number, time) {
    const encodedWord = encodeWord(word);
    const encodedNumber = encodeNumber(number);
    const encodedTime = encodeTime(time);
    return `https://www.theendoftheword.com/ready?word=${encodeURIComponent(
      encodedWord
    )}&number=${encodeURIComponent(encodedNumber)}&time=${encodeURIComponent(
      encodedTime
    )}`;
  }

  const linkToShare = createLink(outgoing, wordChain, selectedTime);

  function deleteCookie(name) {
    if (outgoing.length > 2) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  // Function to set a cookie
  function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  }
  // Function to create a link with encoded word and number
  // function createLink(word, number) {
  //   const encodedWord = encodeWord(word);
  //   return `http://localhost:3000/ready?word=${encodeURIComponent(
  //     encodedWord
  //   )}&number=${encodeURIComponent(timeTook > 0 ? number++ : 0)}`;
  // }

  return (
    <div className="resultscontainer">
      <div className="topDiv">
        <ShareBox totalAlltimeCharacters={totalAlltimeCharacters} />
        {outgoing ? (
          <h3 style={{ marginInline: "35px" }}>
            Share your word{" "}
            <span style={{ color: "#f6d252" }}>"{outgoing}"</span> with a
            friend.
          </h3>
        ) : null}
        <div className="buttonsRow">
          <div>
            {outgoing ? (
              <>
                <Button1
                  className={isWiggling ? "wiggle" : ""}
                  onClick={shareLink}
                >
                  Share
                </Button1>
                {copySuccess ? (
                  <p style={{ color: "#f6d252" }}>{copySuccess}</p>
                ) : null}
              </>
            ) : (
              <Button1
                onClick={() =>
                  (window.location.href = "https://www.theendoftheword.com/")
                }
              >
                Reset
              </Button1>
            )}
          </div>
          <div>
            <Button2 onClick={() => setModalOpen(true)}>
              Spend{" "}
              <div
                style={{
                  color: "white",
                  position: "absolute",
                  top: -5,
                  right: 30,
                  backgroundColor: "#5e49ec",
                  width: "40px",
                  height: "40px",
                  borderRadius: "30px",
                  padding: "2px",
                }}
              >
                <h4 style={{ position: "relative", top: -12 }}>
                  {totalAlltimeCharacters > 999
                    ? "999+"
                    : totalAlltimeCharacters}
                </h4>
              </div>
            </Button2>

            <SpendModal
              isOpen={modalOpen}
              onClose={() => setModalOpen(false)}
              setTime={setSelectedTime}
              selectedTime={selectedTime}
              totalAlltimeCharacters={totalAlltimeCharacters}
            ></SpendModal>
          </div>
        </div>
      </div>
      <div className="borderLine"></div>
      <div className="middleDiv">
        <WebCarousel />
      </div>
      <div className="bottomDiv">
        <Feedback />
      </div>
    </div>
  );
}

export default ResultPage;
