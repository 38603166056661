import React from "react";

import "../styles/feedback.css";

export default function Feedback() {
  const clickLinktoReadyPage = () => {
    window.location.href =
      "https://docs.google.com/forms/d/e/1FAIpQLSf6z2Q7Lc5TdhfXD7KusncK608HPARkp3DPxjpiIHFviGF66w/viewform?usp=sf_link";
  };

  return (
    <div className="feedbackcontainer">
      <h3 onClick={clickLinktoReadyPage}>
        <img src="/rocket.png" alt="rocket" className="rotate-90" /> We want to
        Hear from You!
      </h3>
    </div>
  );
}
