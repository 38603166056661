// PlayGame.jsx
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  setWord,
  setWordChain,
  setTimeLimit,
  loadTotalCharacters,
} from "./gameSlice.jsx";
import { useNavigate, useLocation } from "react-router-dom";
import Button1 from "./button1.jsx";
import Button2 from "./button2.jsx";
import HowTo from "./HowTo.jsx";
import "../styles/readypage.css";

function ReadyPage() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [incoming, setIncoming] = useState();
  const [incomingWordChain, setIncomingWordChain] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);
  const hasCheckedCookie = useRef(false);

  const apocalypseWords = [
    "cataclysm",
    "meteor",
    "asteroid",
    "Armageddon",
    "apocalypse",
    "volcano",
    "disease",
    "famine",
  ];

  useEffect(() => {
    dispatch(loadTotalCharacters());
  }, []);

  useEffect(() => {
    if (hasCheckedCookie.current) return;

    const query = new URLSearchParams(location.search);
    const word = query.get("word");
    const number = query.get("number");
    const time = query.get("time");

    if (word && number && time) {
      const decodedTime = decodeTime(time);
      const decodedWord = decodeWord(word);
      const decodedNumber = decodeNumber(number);
      const savedWord = getCookie("decodedWord");

      if (savedWord && savedWord === decodedWord) {
        setErrorMessage("The link has already been used once before.");
      } else {
        setIncoming(decodedWord);
        setIncomingWordChain(decodedNumber);
        dispatch(setWord(decodedWord));
        dispatch(setWordChain(decodedNumber));
        dispatch(setTimeLimit(decodedTime));
        setCookie("decodedWord", decodedWord, 360);
      }
    } else {
      // No valid word received, select one randomly from the apocalypse-themed words
      const randomWord =
        apocalypseWords[Math.floor(Math.random() * apocalypseWords.length)];
      setIncoming(randomWord);
      dispatch(setWord(randomWord));
      dispatch(setWordChain(0)); // Default word chain starts at 0
      dispatch(setTimeLimit(20)); // Default time limit of 20 seconds
    }
    hasCheckedCookie.current = true;
  }, [location, dispatch]);

  const handleSetWord = () => {
    // dispatch(setWord(incoming ? incoming : " exampleWord"));
    navigate("/play", { replace: true });
  };

  //   decode word from link
  function decodeWord(encodedWord) {
    // Extract the reversed number of added characters from the last four digits
    const reversedCountStr = encodedWord.slice(-4);
    const countStr = reversedCountStr.split("").reverse().join("");
    const addedCount = parseInt(countStr, 10);

    // Remove the added random characters and the four-digit reversed number
    const cleanEncodedWord = encodedWord.slice(
      0,
      encodedWord.length - 4 - addedCount
    );

    // Decode the rail fence cipher
    let rail1 = "";
    let rail2 = "";
    const midPoint = Math.ceil(cleanEncodedWord.length / 2);
    rail1 = cleanEncodedWord.slice(0, midPoint);
    rail2 = cleanEncodedWord.slice(midPoint);

    let decodedWord = "";
    for (let i = 0, j = 0; i < rail1.length || j < rail2.length; i++, j++) {
      if (i < rail1.length) decodedWord += rail1[i];
      if (j < rail2.length) decodedWord += rail2[j];
    }

    return decodedWord;
  }

  function decodeNumber(encodedNumber) {
    // Extract the original length from the last two characters
    const originalLength = parseInt(encodedNumber.slice(-2), 10);
    const encoded = encodedNumber.slice(0, originalLength); // Use the original length to remove padding

    let decodedNumber = "";
    let shift = 3; // The shift used during encoding

    // Reverse the shift operation
    for (let i = 0; i < encoded.length; i++) {
      let digit = parseInt(encoded[i], 10) - shift;
      if (digit < 0) digit += 10; // Handle wrap-around by adding 10
      decodedNumber += digit.toString();
    }

    // Reverse the string to get the original number
    decodedNumber = decodedNumber.split("").reverse().join("");

    return parseInt(decodedNumber, 10);
  }

  function decodeTime(encodedTime) {
    const lengthOfTime = parseInt(encodedTime.slice(-2)); // Get the original length from the last two digits
    encodedTime = encodedTime.slice(0, -2); // Remove the last two digits which store the length

    // Remove the padding
    encodedTime = encodedTime.substring(0, lengthOfTime);

    let decodedTime = "";
    let shift = 3; // Shift amount (must match the encoding shift)

    for (let i = 0; i < encodedTime.length; i++) {
      let digit = parseInt(encodedTime[i], 10) - shift;
      digit = (digit + 10) % 10; // Correct the wrap around
      decodedTime += digit.toString();
    }
    // console.log(parseInt(decodedTime.split("").reverse().join("")));
    return parseInt(decodedTime.split("").reverse().join(""));
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  // Function to set a cookie
  function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  }

  return (
    <div className="container">
      <img
        alt=""
        src="/eotwlogo.png"
        style={{ width: "300px", height: "auto" }}
      />
      {errorMessage ? (
        <div>
          <h1>{errorMessage}</h1>{" "}
          <Button1
            onClick={() =>
              (window.location.href = "https://www.theendoftheword.com/")
            }
          >
            New Game
          </Button1>
        </div>
      ) : (
        <div className="buttons">
          {/* <h1>
            Word chain length {incomingWordChain ? incomingWordChain : "0"}
          </h1> */}
          <Button1 onClick={handleSetWord}>Play</Button1>
          <Button2 onClick={openModal}>How to Play</Button2>
        </div>
      )}

      <HowTo isOpen={showModal} onClose={closeModal}>
        <h2>How to Play</h2>

        <p>1: Create a new word in 20sec or less from two starter letters.</p>
        <p>2: The starter letters are from the last person's played word </p>
        <p>
          3: You can only play words that allow you to chain another word onto
          it.
        </p>

        <p>
          If you fail to do so, the word ends! If your competitor fails on their
          turn, you win the game!
        </p>
        <p>
          Earn Characters for the length of words or how fast you play a
          word...Spend your characters strategically.
        </p>
        <h3>May the Vocabulary be with you!</h3>
      </HowTo>
    </div>
  );
}

export default ReadyPage;
