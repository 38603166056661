import React from "react";
// import Button1 from "./button1";
// import Button2 from "./button2";
import "../styles/sharebox.css";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ShareBox({ totalAlltimeCharacters }) {
  // const navigate = useNavigate();

  const { outgoing, wordChain, timeTook } = useSelector((state) => state.game);

  return (
    <div className="outersharecontainer">
      <span className="share-indicator">Last Play</span>
      <div className="shareContainer">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="small-container">
            <img
              className="icons"
              src="/characters-icon.png"
              alt="characters-icon"
            />

            <p>Characters +</p>
            <h3 className="stats-number">
              {outgoing.length +
                (timeTook !== 0
                  ? timeTook <= 5
                    ? 3
                    : timeTook <= 10
                    ? 2
                    : timeTook <= 5
                    ? 1
                    : 0
                  : 0)}
            </h3>
          </div>
          <div className="small-container">
            {" "}
            <img className="icons" src="/chain-icon.png" alt="chain-icon" />
            <p>Words Chained</p>
            <h3 className="stats-number">{wordChain}</h3>
          </div>
          <div className="small-container">
            {" "}
            <img className="icons" src="/time-icon.png" alt="time-icon" />
            <p>Time Taken</p>
            <h3 className="stats-number">{timeTook}</h3>
          </div>
        </div>
        {/* {console.log(timeTook)} */}
        {timeTook !== 0 && outgoing.length > 2 ? (
          <>
            <p>
              Time Bonus +
              {timeTook <= 5 ? 3 : timeTook <= 10 ? 2 : timeTook <= 15 ? 1 : 0}{" "}
              characters
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default ShareBox;
