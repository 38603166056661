import React from "react";
import Button1 from "./button1.jsx";

function HowTo({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <div
        style={{
          padding: 20,
          background: "#484858",
          borderRadius: "15px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          maxWidth: "70%",
          maxHeight: "90%",
          overflowY: "auto",
        }}
      >
        {" "}
        {children}
        <Button1
          onClick={onClose}
          style={{ float: "right", marginBottom: "10px" }}
        >
          Got it
        </Button1>
      </div>
    </div>
  );
}

export default HowTo;
